<template>
    <div>
        <Divider dashed><span class="divider-text">统计汇总</span></Divider>

        <Row class="table-title p-t-2">
            <i-col span="12" class="p-l-5 p-t-5 p-b-5">类型</i-col>
            <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">数量</i-col>
        </Row>

        <Row v-for="(item,index) in statisticData" :key="index" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
            <i-col span="12" class="p-l-5 p-t-5 p-b-5">{{item.typeName}}</i-col>
            <i-col span="12" class="text-right p-r-5 p-t-5 p-b-5">
               <span v-if="item.id === 1" class="text-16 text-orange">{{item.qunantity}}</span>
               <span v-else>{{item.qunantity}}</span>
            </i-col>
        </Row>
    </div>
</template>

<script>
export default {
  data () {
    return {
      statisticData: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.statisticData = [
        { id: 1, typeName: '可用库存', qunantity: '228' },
        { id: 2, typeName: '人工申报', qunantity: '380' },
        { id: 2, typeName: '强制损耗', qunantity: '380' },
        { id: 3, typeName: '自然损耗', qunantity: '1383' },
        { id: 4, typeName: '损耗率', qunantity: '14.5%' }
      ]
    }
  }
}
</script>
